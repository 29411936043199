<template>
  <div class="uesr">
		<div class="project">
			<div class="search-box">
				<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
					<el-form-item label="用户名">
						<el-input v-model="formSearch.account" placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model="formSearch.realName" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="所属部门">
						<el-select v-model="formSearch.departId" clearable>
							<el-option v-for="(item,index) in departList" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户状态">
						<el-select v-model="formSearch.status" clearable>
							<el-option label="启用" value="0"></el-option>
							<el-option label="停用" value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属项目">
						<el-select v-model="formSearch.tenantId" clearable>
							<el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="创建时间">
						<el-date-picker v-model="formSearch.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
					</el-form-item>
					<el-form-item style="text-align: right;" class="fr">
						<el-button type="primary" @click="handleSearch">检索</el-button>
						<el-button type="success" @click="handleAdd">新增</el-button>
					</el-form-item>
				</el-form>
			</div>
			<!-- 列表 -->
			<div class="table-box">
				<el-card>
					<el-table v-loading="loadingFlag" border :data="tableData" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
						<el-table-column type="selection" width="30"></el-table-column>
						<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
						<el-table-column prop="account" label="用户名" width="100"></el-table-column>
						<!-- <el-table-column prop="password" label="密码" :show-overflow-tooltip="true"></el-table-column> -->
						<el-table-column prop="salt" label="动态密码" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="realName" label="姓名" width="100"></el-table-column>
						<el-table-column prop="roleName" label="用户角色" width="100"></el-table-column>
						<el-table-column prop="departName" label="所属部门" width="200"></el-table-column>
						<el-table-column prop="projectName" label="所属项目"></el-table-column>
						<el-table-column prop="status" label="用户状态" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.status == '0'">启用</span>
								<span v-if="scope.row.status == '1'">停用</span>
							</template>
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
						<el-table-column prop="" label="操作" width="100">
							<template slot-scope="scope">
								<span @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;" class="color-m">编辑</span>
								|
								<span @click="handDelete(scope.$index, scope.row)" style="color: red;cursor: pointer;">删除</span>
							</template>
						</el-table-column>
						<template slot="empty">
							<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
						</template>
					</el-table>
					<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
					<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
					<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
				</el-card>
			</div>
			<!-- 添加  编辑     弹框 -->
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="用户名" prop="account">
						<el-input v-model="ruleForm.account" placeholder="请输入用户名" :disabled="disabledForm"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
					</el-form-item>
					<el-form-item label="姓名" prop="realName">
						<el-input v-model="ruleForm.realName" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="所属省" prop="provinceId">
						<el-select v-model="ruleForm.provinceId" placeholder="请选择所属部门省" @change="getDepartListCity" clearable>
							<el-option v-for="(item,index) in departList" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属市" prop="cityId">
						<el-select v-model="ruleForm.cityId" placeholder="请选择所属部门市" clearable>
							<el-option v-for="(item,index) in departListCity" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户状态" prop="status">
						<el-select v-model="ruleForm.status">
							<el-option label="启用" value="0"></el-option>
							<el-option label="停用" value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属项目" prop="tenantId">
						<el-select v-model="ruleForm.tenantId" placeholder="请选择所属项目" @change="getRoleList">
							<el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户角色" prop="roleId">
						<el-select v-model="ruleForm.roleId" placeholder="请选择用户角色">
							<el-option v-for="(item,index) in roleList" :key="index" :label="item.roleName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
				</span>
			</el-dialog>
		</div>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js'
export default {
  name: 'uesr',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			//创建 时间
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			departList: [],
			departListCity: [],
			projectList:[],
			formSearch: {
				account: '',
				realName: '',
				departId: '',
				status:'',
				tenantId:'',
				time: null,
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 13, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName: "导出所选",
			exportAllName: "导出全部",
			dialogVisible: false,
			dialogTitle: "用户新增",
			disabledForm:false,
			roleList:[],
			ruleForm: {
				id: '',
				account: '',
				password: '',
				realName: '',
				roleId: '',
				departId: '',
				provinceId: '',
				cityId: '',
				status:'',
				tenantId:'',
			},
		}
  },
  created() {
		this.getDepartList(); //加载部门
		this.getProjectList(); //加载用户
		this.getPageList(); //加载列表
  },
  methods: {
		//加载部门  省    检索
		getDepartList() {
			this.$provider.get('/wg-system/depart/list', {}).then(res => {
				this.departList = res.data;
			})
		},
		//加载部门  市
		getDepartListCity(parentId) {
			if(parentId != ""){
				this.$provider.get('/wg-system/depart/list', {
					params:{
						parentId:parentId
					}
				}).then(res => {
					this.departListCity = res.data;
				})
			}
		},
		//加载项目
		getProjectList() {
			this.$provider.get('/wg-system/tenant/list', {}).then(res => {
				this.projectList = res.data;
			})
		},
		//点击查询  按钮
		handleSearch() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/user/page', {
				params: {
					account: this.formSearch.account,
					relName: this.formSearch.relName,
					departId: this.formSearch.departId,
					status: this.formSearch.status,
					tenantId: this.formSearch.tenantId,
					startTime: this.formSearch.time != null ? this.formSearch.time[0] : "",
					endTime: this.formSearch.time != null ? this.formSearch.time[1] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//表格 每行  复选框选值    导出
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr + '/wg-system/user/exportByIds',
					fileName: '用户管理',
					params: 'ids='+chk_value,
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj, this); // 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
				return false;
			}
		},
		//导出全部
		exportALLFn() {
			let startTime = this.formSearch.time != null ? this.formSearch.time[0] : "";
			let endTime = this.formSearch.time != null ? this.formSearch.time[1] : "";
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr + '/wg-system/user/export',
				fileName: '用户管理',
				params: 'account='+this.formSearch.account+'&relName='+this.formSearch.relName+'&departId='+this.formSearch.departId+'&status='+this.formSearch.status+'&tenantId='+this.formSearch.tenantId+'&startTime='+startTime+'&endTime='+endTime,
			}
			this.exportAllName = "正在导出中...";
			exportExcel(myObj, this); // 导出Excel公用方法
		},
			//删除
		handDelete(index,item){
			if(confirm("确定删除本条数据吗？")){
				this.$provider.get('/wg-system/user/delete', {
					params: {
						ids: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('删除失败');
						return false;
					}
				})
			}
		},
		//加载角色
		getRoleList() {
			this.$provider.get('/wg-system/role/queryByUserId', {
				params:{
					tenantId:this.ruleForm.tenantId
				}
			}).then(res => {
				this.roleList = res.data;
			})
		},
		//编辑  弹框打开
		handleEdit(index,item) {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "用户编辑";
			this.disabledForm = true;
			this.ruleForm.id = item.id;
			this.ruleForm.account = item.account;
			this.ruleForm.password = item.password;
			this.ruleForm.realName = item.realName;
			this.ruleForm.provinceId = item.firstDeptId!=null ? item.firstDeptId.toString() : "";
			this.ruleForm.cityId = item.secondDeptId!=null ? item.secondDeptId.toString() : "";
			if(item.firstDeptId != null){
				this.getDepartListCity(item.firstDeptId);//加载部门  市  
			} else {
				this.departListCity = [];
			}
			this.ruleForm.status = item.status;
			this.ruleForm.tenantId = Number(item.tenantId);
			this.ruleForm.roleId = item.roleId;
			this.getRoleList();//加载角色
		},
		//添加  弹框打开
		handleAdd() {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "用户新增";
			this.disabledForm = false;
			this.ruleForm.id = '';
			this.ruleForm.account = '';
			this.ruleForm.password = '';
			this.ruleForm.realName = '';
			this.ruleForm.departId = '';
			this.ruleForm.provinceId = '';
			this.ruleForm.cityId = '';
			this.ruleForm.roleId = '';
			this.ruleForm.status = '';
			this.ruleForm.tenantId = '';
		},
		//添加  编辑  确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
			if (valid) {
				let departId;
				if(this.ruleForm.cityId == ""){
					departId = this.ruleForm.provinceId; 
				} else {
					departId = this.ruleForm.cityId; 
				}
				if (this.dialogTitle == "用户新增") {
					this.$provider.post('/wg-system/user/insert', {
						id: this.ruleForm.id,
						account: this.ruleForm.account,
						password: this.ruleForm.password,
						realName: this.ruleForm.realName,
						roleId: this.ruleForm.roleId,
						departId: departId,
						status: this.ruleForm.status,
						tenantId: this.ruleForm.tenantId,
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '添加成功',
								type: 'success'
							});
							this.dialogVisible = false; //关闭弹框
							this.getPageList();
						} else {
							this.$message.error('添加失败');
							return false;
						}
					})
				} else if (this.dialogTitle == "用户编辑") {
					this.$provider.post('/wg-system/user/update', {
						id: this.ruleForm.id,
						account: this.ruleForm.account,
						password: this.ruleForm.password,
						realName: this.ruleForm.realName,
						roleId: this.ruleForm.roleId,
						departId: departId,
						status: this.ruleForm.status,
						tenantId: this.ruleForm.tenantId,
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '编辑成功',
								type: 'success'
							});
							this.dialogVisible = false; //关闭弹框
							this.getPageList();
						} else {
							this.$message.error('编辑失败');
							return false;
						}
					})
				}
			} else {
				console.log('error submit!!');
				return false;
			}
			});
		},
  }
};
</script>

<style scoped="scoped"></style>
